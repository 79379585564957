import React from 'react';

type ContainerElement = Element | HTMLElement | React.ReactElement | null;
type ContainerFunction = () => ContainerElement;
type ContainerRef =
  | {
      current: ContainerElement;
    }
  | React.MutableRefObject<ContainerElement | null>;

export type Container = ContainerElement | ContainerRef | ContainerFunction;

export default function getContainer(
  container?: Container,
  defaultContainer?: Container,
): Container | undefined | null {
  if (!container) return defaultContainer;

  if (container && 'current' in container) {
    return container.current;
  }

  if (container instanceof HTMLElement) {
    return container;
  }

  if (typeof container === 'function') {
    return getContainer(container(), defaultContainer);
  }

  return defaultContainer;
}
