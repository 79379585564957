import { useContext, Context } from 'react';

/**
 * Returns the current value for a context object, or throws an error in case there is no value
 * @param context - React.context
 * @param options - Parameters to build a descriptive error message. `options` object can contain following fields:
 *   * `hook` - string
 *   * `provider` - string
 * @returns current value for the given context
 * @example
 * import { useContextWithValidation } from '@adsk/alloy-react-helpers';
 *
 * export const useComponentContext = () =>
 *   useContextWithValidation(ComponentContext, {
 *     hook: 'useComponent',
 *     provider: 'ComponentProvider',
 *   });
 * @see DatePicker Notification
 */
export default function useContextWithValidation<T>(
  context: Context<T>,
  options: { hook: string; provider: string },
) {
  const contextValue = useContext(context);
  const { hook, provider } = options;

  if (!contextValue) {
    throw new Error(`${hook} must be used within a ${provider}`);
  }

  return contextValue;
}
