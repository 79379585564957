import React, { ReactNode } from 'react';

import ReactDOM from 'react-dom';

import getContainer, { Container } from './getContainer';

/**
 * Creates a [portal](https://reactjs.org/docs/portals.html) given a parent element and any renderable React children
 * @param children Content to be embedded
 * @param container Element that will contain `children`
 * @returns `container` containing `children`, or children as `React.Fragment`
 * @example
 * import { renderPortal } from '@adsk/alloy-react-helpers';
 *
 * const Portal = ({ children, container }) => renderPortal(children, container);
 * @see Portal
 */
const renderPortal = (children: ReactNode, container?: Container) => {
  if (container) {
    const ref = getContainer(container);
    if (ref instanceof HTMLElement) {
      return <>{ReactDOM.createPortal(children, ref)}</>;
    }
  }

  return <>{children}</>;
};

export default renderPortal;
