import PropTypes from 'prop-types';

import { optionalPropType } from '@adsk/alloy-react-helpers';

export const menuItemShape = PropTypes.shape({
  /** Item key for the option has to be unique among all catagories */
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Label for the option */
  label: PropTypes.node.isRequired,
  /* Icon type to display on left */
  renderIcon: PropTypes.func,
  /* nested menus */
  nested: optionalPropType(PropTypes.array), // arrayOf(menuItemShape)
  /** Determines if the item is selected */
  selected: optionalPropType(PropTypes.bool),
  /** Determines if the V icon will show */
  showSelection: optionalPropType(PropTypes.bool),
  /** Determines if the item is disabled */
  disabled: optionalPropType(PropTypes.bool),
  /** A text on the right side */
  rightSideLabel: optionalPropType(PropTypes.node),
  /** Handle for item click event */
  onClick: optionalPropType(PropTypes.func),
});

export const menuCategoryShape = PropTypes.shape({
  /** Category key for the option */
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Category title */
  title: optionalPropType(PropTypes.string),
  /** options for the action */
  itemOptions: PropTypes.arrayOf(menuItemShape.isRequired).isRequired,
  /** Determines if the V icon will show */
  showSelection: optionalPropType(PropTypes.bool),
  /** Determines if the V icon will show */
  multiSelection: optionalPropType(PropTypes.bool),
  /** allow reselect item, will toggle when false */
  allowReselect: optionalPropType(PropTypes.bool),
});
