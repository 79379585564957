import React, { ReactNode } from 'react';

import PropTypes from 'prop-types';

import { noop, Container } from '@adsk/alloy-react-helpers';
import Overlay, { UseOverlayProps } from '@adsk/alloy-react-overlay';
import Portal from '@adsk/alloy-react-portal';
import theme, { StylableComponent } from '@adsk/alloy-react-theme';

import useTooltip from './useTooltip';

const TRIGGER_TARGETS = {
  CHILDREN: 'children',
  TOOLTIP: 'tooltip',
};

const TRIGGER_EVENTS = {
  CLICK: 'click',
  HOVER: 'hover',
};

type Variants = typeof Overlay.VARIANTS;
type Variant = Variants[keyof Variants];

type Triggers = typeof TRIGGER_TARGETS;
type Trigger = Triggers[keyof Triggers];

type TriggerEvents = typeof TRIGGER_EVENTS;
type TriggerEvent = TriggerEvents[keyof TriggerEvents];

type TooltipProps<TTarget extends HTMLElement> = Omit<
  StylableComponent<HTMLElement>,
  'content'
> &
  UseOverlayProps<TTarget> & {
    container?: Container;
    containerPadding?: number | string;
    content: ReactNode;
    variant?: Variant;
    shouldWrapChildren?: boolean;
    arrowColor?: string;
    showArrow?: boolean;
    arrowSize?: number;
    trigger?: TriggerEvent;
    triggerTarget?: Trigger;
    getTargetProps?: () => Record<string, unknown>;
    wrapper?: keyof React.JSX.IntrinsicElements;
    'data-testid'?: string;
  };

const Tooltip = <TTarget extends HTMLElement>({
  children,
  className,
  style,
  container = document.body,
  content,
  variant = Overlay.VARIANTS.DARK,
  showArrow = true,
  containerPadding = `${theme.spacing.XS}px ${theme.spacing.S}px`,
  wrapper = 'div',
  shouldWrapChildren = true,
  arrowColor,
  arrowSize = 5,
  offset = [0, 10],
  rootClose = true,
  placement: placementProp = Overlay.PLACEMENTS.TOP,
  show: showProp,
  target: targetRef,
  overlayRef,
  getTargetProps = () => ({}),
  onHide: onHideProp = noop,
  onShow: onShowProp = noop,
  trigger = TRIGGER_EVENTS.HOVER,
  triggerTarget = TRIGGER_TARGETS.CHILDREN,
  delayShow = 300,
  delayHide = 0,
  'data-testid': dataTestid,
  ...props
}: TooltipProps<TTarget>) => {
  const {
    show,
    placement,
    interactive,
    targetProps,
    overlayProps,
    arrowProps,
  } = useTooltip({
    rootClose,
    placement: placementProp,
    show: showProp,
    trigger,
    triggerTarget,
    delayShow,
    delayHide,
    onHide: onHideProp,
    onShow: onShowProp,
    getTargetProps,
    target: targetRef,
    overlayRef,
    offset,
    ...props,
  });

  const [_, offsetSize] = offset || [];
  const arrowTheme =
    variant === Overlay.VARIANTS.DARK
      ? theme.tokens.colors.background.inverse.subtle.value
      : theme.tokens.colors.text.inverse.value;

  return (
    <>
      <Overlay.Target
        wrapper={wrapper}
        shouldWrapChildren={shouldWrapChildren}
        {...targetProps}
      >
        {children}
      </Overlay.Target>

      {show && Boolean(content) && (
        <Portal container={container}>
          <Overlay.Container
            placement={placement}
            data-testid={dataTestid}
            {...overlayProps}
          >
            {showArrow && (
              <Overlay.Arrow
                ref={arrowProps.ref}
                style={arrowProps.style}
                placement={placement}
                size={`${arrowSize}px`}
                color={arrowColor ? arrowColor : arrowTheme}
              />
            )}
            <Overlay.Body
              className={className}
              style={style}
              padding={containerPadding}
              variant={variant}
            >
              {content}
            </Overlay.Body>
            {interactive && (
              <Overlay.Blank placement={placement} size={offsetSize} />
            )}
          </Overlay.Container>
        </Portal>
      )}
    </>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /**
   * className to add to the tooltip overlay
   */
  className: PropTypes.string,
  /**
   * style to tooltip overlay
   */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * The contents to show within the popover.
   * hidden when no content available
   */
  content: PropTypes.node,
  /**
   * The content of the element to which the tooltip is applied
   */
  children: PropTypes.node.isRequired,
  /**
   * Tooltip variant
   */
  variant: PropTypes.oneOf(Object.values(Overlay.VARIANTS)),
  /**
   * whether the showing is triggered by click or mouseover
   */
  trigger: PropTypes.oneOf(Object.values(TRIGGER_EVENTS)),
  /**
   * The Overlay target (either the children or the tooltip itself)
   */
  triggerTarget: PropTypes.oneOf(Object.values(TRIGGER_TARGETS)),
  /**
   * Function getter props for trigger functions.
   *  */
  getTargetProps: PropTypes.func,
  /**
   * Overlay position according to Overlay placements.
   * */
  placement: PropTypes.oneOf(Object.values(Overlay.PLACEMENTS)),
  /**
   * Target element ref (get/set).
   * */
  target: PropTypes.object,
  /**
   * Overlay ref to overlay dom element.
   *  */
  overlayRef: PropTypes.any,
  /**
   * Specify whether the overlay should trigger `onHide` when the user clicks outside the overlay.
   * */
  rootClose: PropTypes.bool,
  /**
   * Dependency to pass to usePopper's useLayoutEffect hook
   * */
  layoutDependency: PropTypes.any,
  /**
   * whether the popover is showed or not. should be sent only for
   * controlled elements
   */
  show: PropTypes.bool,
  /**
   * callback to be called on show
   */
  onShow: PropTypes.func,
  /**
   * callback to be called on hide
   */
  onHide: PropTypes.func,
  /**
   * delay before show in milliseconds
   */
  delayShow: PropTypes.number,
  /**
   * delay before hide in milliseconds
   */
  delayHide: PropTypes.number,
  /**
   * whether to not have the arrow - show and default to true
   */
  showArrow: PropTypes.bool,
  /**
   * arrow color, eg: red, #000000, rgba(128,128,128,0.5)
   */
  arrowColor: PropTypes.string,
  /**
   * arrow size number in pixels
   */
  arrowSize: PropTypes.number,
  /**
   * should wrap children with `wrapper` element,
   * note it will overwrite your events onClick,
   * onMouseEnter, onMouseLeave, onFocus, onBlur
   */
  shouldWrapChildren: PropTypes.bool,
  /**
   * wrap children as element eg: div or span
   */
  wrapper: PropTypes.string,
  /**
   * Control how much space there is between the edge of the boundary element and overlay.
   */
  containerPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Options that modify how the tooltip overlay can shift (see floating-ui documentation https://floating-ui.com/docs/shift) */
  shiftArgs: PropTypes.any,
  /** Options that modify how the tooltip overlay can flip (see floating-ui documentation https://floating-ui.com/docs/flip) */
  flipArgs: PropTypes.any,
  /** Options that modify how the tooltip overlay can hide (when target is hidden) (see floating-ui documentation https://floating-ui.com/docs/hide) */
  hideArgs: PropTypes.any,
};

export default Object.assign(Tooltip, {
  VARIANTS: Overlay.VARIANTS,
  PLACEMENTS: Overlay.PLACEMENTS,
  TRIGGER_EVENTS,
  TRIGGER_TARGETS,
});
