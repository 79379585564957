import React from 'react';

import { useForwardedRef } from '@adsk/alloy-react-helpers';
import { useOverlay, UseOverlayProps } from '@adsk/alloy-react-overlay';

import Tooltip from './Tooltip';

type Triggers = typeof Tooltip.TRIGGER_TARGETS;
type Trigger = Triggers[keyof Triggers];

type TriggerEvents = typeof Tooltip.TRIGGER_EVENTS;
type TriggerEvent = TriggerEvents[keyof TriggerEvents];

export type UseTooltipProps<TTarget extends HTMLElement> =
  UseOverlayProps<TTarget> & {
    trigger?: TriggerEvent;
    triggerTarget?: Trigger;
    /** function getter props for trigger functions. */
    getTargetProps: () => Record<string, unknown>;
  };

function useTooltip<TTarget extends HTMLElement>({
  rootClose,
  placement: placementProp,
  show: showProp,
  trigger,
  triggerTarget,
  delayShow,
  delayHide,
  onHide: onHideProp,
  onShow: onShowProp,
  getTargetProps,
  target: targetRef,
  overlayRef,
  offset,
  hideArgs = { strategy: 'referenceHidden' },
  ...props
}: UseTooltipProps<TTarget>) {
  const isHover = trigger === Tooltip.TRIGGER_EVENTS.HOVER;
  const interactive = triggerTarget === Tooltip.TRIGGER_TARGETS.TOOLTIP;
  const target = useForwardedRef(targetRef || null);
  const isControlled = showProp !== undefined;

  const {
    show,
    placement,
    targetProps,
    overlayProps,
    arrowProps,
    onShow,
    onHide,
    handleToggle,
  } = useOverlay({
    placement: placementProp,
    show: showProp,
    delayShow,
    delayHide,
    onHide: onHideProp,
    onShow: onShowProp,
    target,
    overlayRef,
    rootClose: rootClose && !isHover,
    offset,
    hideArgs,
    ...props,
  });

  let events = {};

  if (!isControlled) {
    events = {
      onFocus: isHover ? onShow : undefined,
      onBlur: isHover ? onHide : undefined,
      onMouseEnter: isHover ? onShow : undefined,
      onMouseLeave: isHover ? onHide : undefined,
      onClick: !isHover ? handleToggle : undefined,
    };
  }

  return {
    show,
    placement,
    interactive,
    targetProps: {
      ref: targetProps.ref,
      ...events,
      ...getTargetProps(),
    },
    arrowProps,
    overlayProps: {
      onMouseEnter: interactive ? onShow : undefined,
      onMouseLeave: interactive ? onHide : undefined,
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        event.stopPropagation(),
      ...overlayProps,
    },
  };
}

export default useTooltip;
