/**
 * Mostly used for CSS, allows to choose an option from a set of variants using a statement
 * @param options - options object can contain following fields:
 *   * `statement` - contains the key for the desired variant
 *   * `variants` - object containing sets of properties
 * @returns Value of variants object which uses `statement` as key
 * @example
 * import { variantSwitch } from '@adsk/alloy-react-helpers';
 *
 * const Element = React.forwardRef<HTMLDivElement, ElementProps>(
 *   ({ layout, style, className, ...props }, ref) => {
 *     return (
 *       <div
 *         {...props}
 *         ref={ref}
 *         className={className}
 *         css={[
 *           variantSwitch({
 *             statement: layout,
 *             variants: {
 *               [PROPERTIES.VARIANT_1]: {
 *                 height: 2,
 *               },
 *               [PROPERTIES.VARIANT_2]: {
 *                 width: 2,
 *               },
 *             },
 *           }),
 *           style,
 *         ]}
 *       />
 *     );
 *   }
 * );
 * @see Avatar Badge Banner Button DatePicker Divider FormField Menu Modal Notification Overlay PermissionSelect StepIndicator WorkflowBar
 */
export default function variantSwitch<V>({
  statement,
  variants,
}: {
  statement?: string;
  variants: { [key: string]: V };
}) {
  return (statement !== undefined && variants[statement]) || null;
}
