import React from 'react';

import PropTypes from 'prop-types';
import { CSSObject } from 'styled-components';

import { stylePropType, StylableComponent } from '@adsk/alloy-react-theme';

const PanelContainer = React.forwardRef<
  HTMLDivElement,
  StylableComponent<HTMLDivElement>
>(({ children, style, ...props }, ref) => (
  <div
    {...props}
    css={[
      {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
      },
      style as CSSObject,
    ]}
    ref={ref}
  >
    {children}
  </div>
));

PanelContainer.displayName = 'PanelContainer';

PanelContainer.propTypes = {
  /** Panel content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
};

export default PanelContainer;
