import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme, { stylePropType } from '@adsk/alloy-react-theme';

import BasicButton from './BasicButton';
import { buttonTypePropType, StylableButtonProps } from '../consts';

const BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

type ButtonSizes = typeof BUTTON_SIZES;
type ButtonSize = ButtonSizes[keyof ButtonSizes];

/**
 * Link Button.
 */
const LinkButton = React.forwardRef<
  HTMLButtonElement,
  StylableButtonProps & {
    disabled?: boolean;
    size?: ButtonSize;
    target?: string; // Might have a better way to add those props based on `as` prop.
    href?: string;
    rel?: string;
  }
>(
  (
    {
      style,
      className,
      children,
      disabled,
      size = BUTTON_SIZES.MEDIUM,
      onClick,
      ...props
    },
    ref,
  ) => (
    <BasicButton
      ref={ref}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={[
        {
          color: theme.tokens.colors.text.link.value,
          padding: 0,

          '&:hover': {
            color: theme.tokens.colors.text.hover.value,
          },
          '&:focus': {
            color: theme.tokens.colors.text.link.value,
            boxShadow: theme.halos.focus,
          },
          '&:active': {
            boxShadow: 'none',
            color: theme.tokens.colors.text.pressed.value,
          },
          '&[disabled]': {
            color: theme.tokens.colors.text.inactive.value,
            cursor: 'not-allowed',
          },
        },
        variantSwitch({
          statement: size,
          variants: {
            [BUTTON_SIZES.SMALL]: theme.typography.bodySmall,
            [BUTTON_SIZES.MEDIUM]: theme.typography.bodyMedium,
          },
        }),
        style,
      ]}
      {...props}
    >
      {children}
    </BasicButton>
  ),
);

LinkButton.displayName = 'LinkButton';

LinkButton.propTypes = {
  /** LinkButton's content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /**  The size of the button, defaults to 'medium' */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** button html type, eg: submit */
  type: buttonTypePropType,
  /** Disable button functionality */
  disabled: PropTypes.bool,
  /** handle onclick event for button */
  onClick: PropTypes.func,
};

export default Object.assign(LinkButton, {
  SIZES: BUTTON_SIZES,
});
