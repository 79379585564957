import { MutableRefObject, useMemo } from 'react';

import {
  useFloating,
  autoUpdate,
  arrow,
  offset,
  flip,
  shift,
  hide,
  Middleware,
  ShiftOptions,
  FlipOptions,
  HideOptions,
} from '@floating-ui/react-dom';
import { CSSObject } from 'styled-components';

import type { Placement } from '../types';

export default function usePopper({
  placement: initialPlacement = 'bottom',
  offset: offsetProp,
  fixed = false,
  targetElement,
  overlayElement,
  arrowElement,
  shiftArgs,
  flipArgs,
  hideArgs,
  show,
  isOverlayConditionallyRendered = true,
}: {
  placement?: Placement;
  offset?: number[];
  fixed?: boolean;
  targetElement: Element | null;
  overlayElement: HTMLElement | null;
  arrowElement: HTMLElement | MutableRefObject<HTMLElement | null> | null;
  shiftArgs?: ShiftOptions;
  flipArgs?: FlipOptions;
  hideArgs?: HideOptions;
  show: boolean;
  isOverlayConditionallyRendered?: boolean;
}) {
  const middleware = useMemo(() => {
    const toReturn: Middleware[] = [];

    /**
     * Do not add middleware if overlay is not shown and rendered.
     * This causes unnecessary calculation and rerendering
     */

    if (isOverlayConditionallyRendered || show) {
      if (offsetProp) {
        // TODO this awkwardness is to support our current API, if we break we can adopt the new options object
        const [crossAxis, mainAxis] = offsetProp;

        toReturn.push(offset({ mainAxis, crossAxis }));
      }

      toReturn.push(flip(flipArgs));
      toReturn.push(shift(shiftArgs));

      if (arrowElement) {
        toReturn.push(arrow({ element: arrowElement }));
      }

      toReturn.push(hide(hideArgs));
    }

    return toReturn;
  }, [
    shiftArgs,
    hideArgs,
    isOverlayConditionallyRendered,
    show,
    arrowElement,
    offsetProp,
    flipArgs,
  ]);

  const { refs, middlewareData, floatingStyles, update, ...rest } = useFloating(
    {
      placement: initialPlacement,
      whileElementsMounted: autoUpdate,
      elements: {
        reference: targetElement,
        floating: overlayElement,
      },
      open: show,
      middleware,
      strategy: fixed ? 'fixed' : 'absolute',
    },
  );

  if (hideArgs?.strategy && overlayElement) {
    overlayElement.style.visibility = middlewareData.hide?.[hideArgs.strategy]
      ? 'hidden'
      : 'visible';
  }

  const arrowStyles: CSSObject = {
    transform: `translate(${Math.round(
      middlewareData.arrow?.x || 0,
    )}px,${Math.round(middlewareData.arrow?.y || 0)}px)`,
  };

  return {
    // floatingStyles is an object, but is typed as CSSProperties, which includes `string` so we cast it to keep our current API
    // We could remove this cast in a breaking change
    popoverStyles: floatingStyles as CSSObject,
    arrowStyles,
    refs,
    ...rest,
  };
}
