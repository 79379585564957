import React from 'react';

import PropTypes from 'prop-types';
import styledC, { css, keyframes } from 'styled-components';

import { useInstanceId } from '@adsk/alloy-react-helpers';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const spinAnimation = css`
  animation: ${spin} 0.6s linear infinite;
`;

const PROGRESS_RING_SIZES = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
} as const;

type ProgressRingSizes = typeof PROGRESS_RING_SIZES;
export type ProgressRingSize = ProgressRingSizes[keyof ProgressRingSizes];

const PROGRESS_RING_VARIANTS = {
  DEFAULT: 'default',
  ALTERNATIVE: 'alternative',
} as const;

type ProgressRingVariants = typeof PROGRESS_RING_VARIANTS;
type ProgressRingVariant = ProgressRingVariants[keyof ProgressRingVariants];

const mapConstToSize = {
  [PROGRESS_RING_SIZES.XLARGE]: 216,
  [PROGRESS_RING_SIZES.LARGE]: 128,
  [PROGRESS_RING_SIZES.MEDIUM]: 64,
  [PROGRESS_RING_SIZES.SMALL]: 24,
  [PROGRESS_RING_SIZES.XSMALL]: 16,
} as const;

type InnerDivProps = {
  $indeterminate: boolean;
  $size: number;
  $variant: ProgressRingVariant;
};

const StyledDiv = styledC.div<InnerDivProps>`
  display: inline-block;
  color: ${(props) =>
    props.$variant === PROGRESS_RING_VARIANTS.ALTERNATIVE
      ? theme.tokens.colors.icon.inverse.value
      : theme.tokens.colors.icon.load.value};
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  transform: rotate(${(props) => (props.$indeterminate ? 0 : -90)}deg);
  ${(props) => (props.$indeterminate ? spinAnimation : '')}
`;

/**
 * ProgressRings can be used to show the loading state in your projects.
 */
const ProgressRing = React.forwardRef<
  HTMLDivElement,
  StylableComponent<HTMLDivElement> & {
    variant?: ProgressRingVariant;
    now?: number;
    size?: ProgressRingSize;
  }
>(
  (
    {
      style,
      className,
      children: _children,
      variant = PROGRESS_RING_VARIANTS.DEFAULT,
      now,
      size = PROGRESS_RING_SIZES.SMALL,
      ...props
    },
    ref,
  ) => {
    const sizeInPixels = mapConstToSize[size];
    const THICKNESS = size === PROGRESS_RING_SIZES.XSMALL ? 2 : 4;
    const circumference = 2 * Math.PI * ((sizeInPixels - THICKNESS) / 2);
    const fixedCircumference = +circumference.toFixed(3);
    const circleProps = {
      cx: sizeInPixels,
      cy: sizeInPixels,
      r: (sizeInPixels - THICKNESS) / 2,
      fill: 'none',
      strokeWidth: THICKNESS,
    };
    const indeterminate = isNaN(now as number);
    const alternative = variant === PROGRESS_RING_VARIANTS.ALTERNATIVE;
    const instanceId = `alloy-progress-ring-${useInstanceId()}`;
    return (
      <StyledDiv
        role="progressbar"
        aria-label="progressbar"
        aria-valuetext={indeterminate ? 'indeterminate' : undefined}
        aria-valuenow={indeterminate ? undefined : now}
        aria-valuemin={0}
        aria-valuemax={100}
        {...props}
        className={className}
        ref={ref}
        $indeterminate={indeterminate}
        $variant={variant}
        $size={sizeInPixels}
        css={style}
      >
        <svg
          css={[
            {
              display: 'block',
            },
          ]}
          viewBox={`${sizeInPixels / 2} ${
            sizeInPixels / 2
          } ${sizeInPixels} ${sizeInPixels}`}
        >
          <defs role="presentation">
            <linearGradient
              gradientTransform="rotate(270)"
              id={`${instanceId}-indeterminateBlue`}
              x1="50%"
              y1="100%"
              x2="50%"
              y2="0%"
            >
              <stop
                offset="0%"
                stopOpacity="0"
                stopColor={theme.tokens.colors.icon.load.value}
              />
              <stop
                offset="100%"
                stopOpacity="1"
                stopColor={theme.tokens.colors.icon.load.value}
              />
            </linearGradient>
            <linearGradient
              gradientTransform="rotate(270)"
              id={`${instanceId}-indeterminateWhite`}
              x1="50%"
              y1="100%"
              x2="50%"
              y2="0%"
            >
              <stop
                offset="0%"
                stopOpacity="0"
                stopColor={theme.tokens.colors.icon.inverse.value}
              />
              <stop
                offset="100%"
                stopOpacity="1"
                stopColor={theme.tokens.colors.icon.inverse.value}
              />
            </linearGradient>
          </defs>
          <circle
            css={[
              {
                stroke: alternative
                  ? 'rgba(255, 255, 255, 0.25)'
                  : 'rgba(0, 0, 0, .05)',
              },
            ]}
            {...circleProps}
          />
          <circle
            css={[
              {
                strokeLinecap: 'round',
                ...(indeterminate
                  ? {
                      stroke: alternative
                        ? `url(#${instanceId}-indeterminateWhite)`
                        : `url(#${instanceId}-indeterminateBlue)`,
                      strokeDasharray: fixedCircumference,
                      strokeDashoffset: fixedCircumference / 2,
                    }
                  : {
                      stroke: 'currentColor',
                      strokeDasharray: fixedCircumference,
                      strokeDashoffset: `${
                        ((100 - Math.min(now ?? 0, 100)) / 100) *
                        fixedCircumference
                      }px`,
                      transition: 'stroke-dashoffset linear 0.2s',
                    }),
              },
            ]}
            {...circleProps}
          />
        </svg>
      </StyledDiv>
    );
  },
);

ProgressRing.displayName = 'ProgressRing';

ProgressRing.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /**  The component's children */
  children: PropTypes.any,
  /** Current ProgressRing value, if omitted would animate automatically. */
  now: PropTypes.number,
  /** The ProgressRing variant */
  variant: PropTypes.oneOf(Object.values(PROGRESS_RING_VARIANTS)),
  /**  The size of the ProgressRing, defaults to 'small' */
  size: PropTypes.oneOf(Object.values(PROGRESS_RING_SIZES)),
};

export default Object.assign(ProgressRing, {
  SIZES: PROGRESS_RING_SIZES,
  VARIANTS: PROGRESS_RING_VARIANTS,
});
