import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import { PLACEMENTS } from '../consts';
import { getMainAxisPlacement } from '../helpers';
import type { Placement } from '../types';

type ArrowProps = StylableComponent<HTMLDivElement> & {
  placement?: Placement;
  size?: string;
};

const Arrow = React.forwardRef<HTMLDivElement, ArrowProps>(
  (
    {
      className,
      style,
      placement,
      color = theme.tokens.colors.text.primary.value,
      size = '5px',
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={className}
      css={[
        {
          position: 'absolute',
          width: 0,
          height: 0,
          borderStyle: 'solid',
        },
        variantSwitch({
          statement: getMainAxisPlacement(placement),
          variants: {
            [PLACEMENTS.LEFT]: {
              left: '100%',
              borderWidth: `${size} 0 ${size} ${size}`,
              borderColor: `transparent transparent transparent ${color}`,
              filter: `drop-shadow(1px 0 1px ${theme.colors._blueShadow})`,
              transform: 'translateX(-1px)',
            },
            [PLACEMENTS.RIGHT]: {
              right: '100%',
              borderWidth: `${size} ${size} ${size} 0`,
              borderColor: `transparent ${color} transparent transparent`,
              filter: `drop-shadow(-1px 0 1px ${theme.colors._blueShadow})`,
              transform: 'translateX(1px)',
            },
            [PLACEMENTS.TOP]: {
              top: '100%',
              borderWidth: `${size} ${size} 0`,
              borderColor: `${color} transparent transparent transparent`,
              filter: `drop-shadow(0 1px 1px ${theme.colors._blueShadow})`,
              transform: 'translateY(-1px)',
            },
            [PLACEMENTS.BOTTOM]: {
              bottom: '100%',
              borderWidth: `0 ${size} ${size}`,
              borderColor: `transparent transparent ${color} transparent`,
              filter: `drop-shadow(0 -1px 1px ${theme.colors._blueShadow})`,
              transform: 'translateY(1px)',
            },
          },
        }),
        style,
      ]}
    />
  ),
);

Arrow.displayName = 'Arrow';

Arrow.propTypes = {
  /** className */
  className: PropTypes.string,
  /** style */
  style: stylePropType,
  /** placement */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
  /** arrow color */
  color: PropTypes.string,
  /** arrow size */
  size: PropTypes.string,
};

export default Object.assign(Arrow, { PLACEMENTS });
