import { variantSwitch } from '@adsk/alloy-react-helpers';
import theme from '@adsk/alloy-react-theme';

import { SIDES, PLACEMENTS, VARIANTS } from './consts';
import { Placement, Side, ThemeVariant } from './types';

export const getMainAxisPlacement = (placement?: Placement): Side => {
  switch (placement) {
    case SIDES.LEFT:
    case PLACEMENTS.LEFT_START:
    case PLACEMENTS.LEFT_END:
      return SIDES.LEFT;
    case PLACEMENTS.RIGHT:
    case PLACEMENTS.RIGHT_START:
    case PLACEMENTS.RIGHT_END:
      return SIDES.RIGHT;
    case PLACEMENTS.TOP:
    case PLACEMENTS.TOP_START:
    case PLACEMENTS.TOP_END:
      return SIDES.TOP;
    case PLACEMENTS.BOTTOM:
    case PLACEMENTS.BOTTOM_START:
    case PLACEMENTS.BOTTOM_END:
    default:
      return SIDES.BOTTOM;
  }
};

export const getContainerBodyStyle = (variant?: ThemeVariant) => {
  return variantSwitch({
    statement: variant,
    variants: {
      [VARIANTS.LIGHT]: {
        color: theme.tokens.colors.text.primary.value,
        backgroundColor: theme.tokens.colors.background.primary.value,
        borderRadius: theme.borderRadius.medium,
        boxShadow: theme.shadows.highElevation,
      },
      [VARIANTS.DARK]: {
        color: theme.tokens.colors.text.inverse.value,
        backgroundColor: theme.tokens.colors.background.inverse.subtle.value,
        borderRadius: theme.borderRadius.small,
      },
    },
  });
};
