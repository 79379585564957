import React, {
  useRef,
  useState,
  useEffect,
  ComponentProps,
  ReactElement,
} from 'react';

import PropTypes from 'prop-types';

import { noop, optionalPropType } from '@adsk/alloy-react-helpers';
import { useOverlay } from '@adsk/alloy-react-overlay';

import MenuItem from './MenuItem';
import { getAllNestedKeys, getSelectedItems } from '../helpers/helpers';
import { menuCategoryShape } from '../helpers/propTypes';
import { MenuCategoryType, MenuItemType, SharedProps } from '../helpers/types';
import Menu from '../Menu';

/** A OverlayMenuItem component which handles nested items overlay */
const defaultOffset = [-10, 0];
const reversedOffset = [10, 0];

const checkHasSelectedNested = <TKey extends string | number>(
  nested: MenuItemType<TKey>['nested'],
  value: TKey[],
) => {
  if (!nested) {
    return false;
  }
  const allNestedKeys = getAllNestedKeys(nested);
  return allNestedKeys?.some((k) => value.includes(k));
};

type OverlayMenuItemProps<TKey extends string | number> = Omit<
  ComponentProps<typeof MenuItem>,
  'nested' | 'onChange' | 'value' | 'defaultValue'
> &
  SharedProps<TKey> & {
    itemKey: TKey;
    nested?: MenuItemType<TKey>['nested'];
    category?: MenuCategoryType<TKey>;
    onClose?: () => void;
    renderItem?: (props: ComponentProps<typeof MenuItem>) => ReactElement;
  };

const OverlayMenuItem = <TKey extends string | number>({
  itemKey: key,
  nested,
  category,
  defaultValue,
  value = [],
  onChange = noop,
  onClose,
  renderItem = (p) => <MenuItem {...p} />,
  ...rest
}: OverlayMenuItemProps<TKey>) => {
  const targetRef = useRef<HTMLButtonElement | null>(null);
  const [offset, setOffset] = useState(defaultOffset);
  const { show, targetProps, overlayProps, onShow, onHide, placement } =
    useOverlay({
      placement: useOverlay.PLACEMENTS.RIGHT_START,
      target: targetRef,
      offset,
    });

  useEffect(() => {
    if (placement !== useOverlay.PLACEMENTS.RIGHT_START) {
      setOffset(reversedOffset);
    }
  }, [placement]);

  const selected = value.includes(key);

  const hasSelectedNested = checkHasSelectedNested(nested, value);

  const handleClose = (cat: MenuCategoryType<TKey>) =>
    onClose && cat.showSelection === false && onClose();

  const add = (eventKey: TKey, cat: MenuCategoryType<TKey>) => {
    onChange(getSelectedItems(value, eventKey, cat));
  };

  const remove = (eventKey: TKey) => {
    onChange(value.filter((k) => k !== eventKey));
  };

  // There is a bug in react where if you are coming from a disabled element
  // into an enabled element, mouse enter is not fired (as the event originates
  // from the disabled element): https://github.com/facebook/react/issues/19419
  // This mouse over handler is redundant and exists solely to help fix the
  // above situation
  const onMouseOver = () => {
    if (!show) {
      onShow();
    }
  };

  return renderItem({
    ...rest,
    ...targetProps,
    onMouseEnter: onShow,
    onMouseOver,
    onMouseLeave: onHide,
    ref: targetRef,
    'data-testid': `menu-item--${key}`,
    nested: !!nested,
    selected: selected || hasSelectedNested,
    showSelection: category?.showSelection,
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (nested) {
        return;
      }

      if (rest.onClick) {
        rest.onClick(e);
      }

      if (!category) return;
      if (category.allowReselect) {
        add(key, category);
      } else {
        if (selected) {
          remove(key);
        } else {
          add(key, category);
        }
      }
      handleClose(category);
    },
    children:
      nested && show ? (
        <Menu
          {...overlayProps}
          options={nested}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onClose={onClose}
        />
      ) : undefined,
  });
};

OverlayMenuItem.displayName = 'MenuItemWrapper';

OverlayMenuItem.propTypes = {
  ...MenuItem.propTypes,
  /** Item key for the option has to be unique among all catagories */
  itemKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** The category of this item */
  category: menuCategoryShape,
  /** nested menu items */
  nested: PropTypes.array,
  /** Pre selected keys */
  defaultValue: optionalPropType(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ),
  ),
  /** Value for controlled usage */
  value: optionalPropType(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ),
  ),
  /** Handle for item click event */
  onChange: PropTypes.func,
  /** Handle closing of the menu */
  onClose: PropTypes.func,
  /** render custom menu item */
  renderItem: PropTypes.elementType,
};

export default OverlayMenuItem as <TKey extends string | number>(
  p: OverlayMenuItemProps<TKey>,
) => ReactElement;
