import { useContext } from 'react';

import LocaleContext from '../context';

export default () => {
  const contextValue = useContext(LocaleContext);

  return {
    locale: contextValue?.locale,
    setLocale: contextValue?.setLocale,
  };
};
