import React from 'react';

import PropTypes from 'prop-types';

import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

type MenuListProps = StylableComponent<HTMLDivElement>;
/** A MenuList component which wraps an HTML unordered list */
const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  ({ children, style, className }, ref) => {
    return (
      <div
        ref={ref}
        className={className}
        css={[
          {
            listStyle: 'none',
            padding: `${theme.spacing.XS}px 0px`,
            margin: 0,
          },
          style,
        ]}
      >
        {children}
      </div>
    );
  },
);

MenuList.displayName = 'MenuList';

MenuList.propTypes = {
  /** The content to category */
  children: PropTypes.any,
  /* Styles applied to the root element */
  style: stylePropType,
  /* Class applied to the root element */
  className: PropTypes.string,
};
export default MenuList;
