import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M13.5166 3.45625C13.8169 3.74154 13.829 4.21626 13.5438 4.51656L5.94375 12.5166C5.80216 12.6656 5.60558 12.75 5.4 12.75C5.19442 12.75 4.99785 12.6656 4.85625 12.5166L2.45625 9.99025C2.17096 9.68994 2.18313 9.21523 2.48344 8.92994C2.78374 8.64465 3.25846 8.65682 3.54375 8.95712L5.4 10.9111L12.4563 3.48344C12.7415 3.18313 13.2163 3.17096 13.5166 3.45625Z';
const draw24px =
  'M8.5,17.5A.74.74,0,0,1,8,17.28l-3.5-3.5a.75.75,0,0,1,1.06-1.06l3,3,10-10a.75.75,0,0,1,1.06,1.06L9,17.28A.74.74,0,0,1,8.5,17.5Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const CheckmarkIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'checkmark'),
) as IconWithRender;

CheckmarkIcon.displayName = 'CheckmarkIcon';
CheckmarkIcon.propTypes = Icon.propTypes;
CheckmarkIcon.renderIcon = (props) => <CheckmarkIcon {...props} />;
export default CheckmarkIcon;
