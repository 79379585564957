import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M2 8a1.2 1.2 0 1 1 1.2 1.2A1.21 1.21 0 0 1 2 8Zm6 1.2A1.21 1.21 0 1 0 6.8 8 1.2 1.2 0 0 0 8 9.2Zm4.8 0A1.21 1.21 0 1 0 11.6 8a1.2 1.2 0 0 0 1.2 1.2Z';
const draw24px =
  'M6,10.5A1.5,1.5,0,1,1,4.5,12,1.5,1.5,0,0,1,6,10.5ZM10.5,12A1.5,1.5,0,1,0,12,10.5,1.5,1.5,0,0,0,10.5,12Zm6,0A1.5,1.5,0,1,0,18,10.5,1.5,1.5,0,0,0,16.5,12Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const MoreHorizontalIcon = forwardRef<
  SVGSVGElement,
  ComponentProps<typeof Icon>
>(generateRenderedIcon(icons, 'moreHorizontal')) as IconWithRender;

MoreHorizontalIcon.displayName = 'MoreHorizontalIcon';
MoreHorizontalIcon.propTypes = Icon.propTypes;
MoreHorizontalIcon.renderIcon = (props) => <MoreHorizontalIcon {...props} />;
export default MoreHorizontalIcon;
