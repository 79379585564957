import { useState, useRef, useCallback, useEffect } from 'react';

import { noop } from '@adsk/alloy-react-helpers';

export type UseVisibilityProps = {
  /**
   * whether the popover is showed or not. should be sent only for
   * controlled elements
   */
  show?: boolean;
  /* callback to be called on hide */
  onHide?: () => void;
  /* callback to be called on show */
  onShow?: () => void;
  /* delay before show in milliseconds */
  delayShow?: number;
  /* delay before hide in milliseconds */
  delayHide?: number;
};

function useVisibility({
  show: controlledShow,
  onHide: onHideProp = noop,
  onShow: onShowProp = noop,
  delayShow,
  delayHide,
}: UseVisibilityProps) {
  const [isControlled, setIsControlled] = useState(
    controlledShow !== undefined,
  );
  const [show, setShow] = useState(controlledShow || false);
  const timeoutShowRef = useRef<ReturnType<typeof setTimeout>>();
  const timeoutHideRef = useRef<ReturnType<typeof setTimeout>>();

  const clearShowAndHideTimeouts = useCallback(() => {
    clearTimeout(timeoutShowRef.current);
    clearTimeout(timeoutHideRef.current);
  }, []);

  // this is required by OverflowTooltip as it can change from controlled to
  // uncontrolled and vice versa
  useEffect(() => {
    setIsControlled(controlledShow !== undefined);
  }, [controlledShow]);

  useEffect(() => {
    // Clear timeouts when unmounting
    return clearShowAndHideTimeouts;
  }, [clearShowAndHideTimeouts]);

  const onShow = useCallback(() => {
    clearShowAndHideTimeouts();

    timeoutShowRef.current = setTimeout(() => {
      onShowProp();
      if (!isControlled) {
        setShow(true);
      }
    }, delayShow);
  }, [clearShowAndHideTimeouts, delayShow, isControlled, onShowProp]);

  const onHide = useCallback(() => {
    clearShowAndHideTimeouts();

    timeoutHideRef.current = setTimeout(() => {
      onHideProp();
      if (!isControlled) {
        setShow(false);
      }
    }, delayHide);
  }, [clearShowAndHideTimeouts, delayHide, isControlled, onHideProp]);

  const handleToggle = useCallback(() => {
    const showValue = isControlled ? controlledShow : show;
    showValue ? onHide() : onShow();
  }, [isControlled, controlledShow, show, onHide, onShow]);

  return {
    show: isControlled ? !!controlledShow : show,
    onHide,
    onShow,
    /** @deprecated use onShow because functionality has been combined and made the same */
    handleShow: onShow,
    /** @deprecated use onHide because functionality has been combined and made the same */
    handleHide: onHide,
    handleToggle,
  };
}

export default useVisibility;
