import React from 'react';

import PropTypes from 'prop-types';

import { variantSwitch } from '@adsk/alloy-react-helpers';
import { stylePropType, StylableComponent } from '@adsk/alloy-react-theme';

import { PLACEMENTS } from '../consts';
import { getMainAxisPlacement } from '../helpers';
import type { Placement } from '../types';

type BlankProps = StylableComponent<HTMLDivElement> & {
  placement?: Placement;
  size?: string | number;
};

const Blank = React.forwardRef<HTMLDivElement, BlankProps>(
  ({ className, style, placement, size, ...props }, ref) => (
    <div
      ref={ref}
      className={className}
      css={[
        { position: 'absolute' },
        variantSwitch({
          statement: getMainAxisPlacement(placement),
          variants: {
            [PLACEMENTS.LEFT]: {
              top: 0,
              left: '100%',
              width: size,
              height: '100%',
            },
            [PLACEMENTS.RIGHT]: {
              top: 0,
              right: '100%',
              width: size,
              height: '100%',
            },
            [PLACEMENTS.TOP]: {
              left: 0,
              top: '100%',
              width: '100%',
              height: size,
            },
            [PLACEMENTS.BOTTOM]: {
              left: 0,
              bottom: '100%',
              width: '100%',
              height: size,
            },
          },
        }),
        style,
      ]}
      {...props}
    />
  ),
);

Blank.displayName = 'Blank';

Blank.propTypes = {
  /** className */
  className: PropTypes.string,
  /** Size */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** style */
  style: stylePropType,
  /** placement */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
};

export default Object.assign(Blank, { PLACEMENTS });
