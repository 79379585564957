import React, {
  useRef,
  useState,
  useCallback,
  ReactElement,
  ComponentProps,
  ReactNode,
} from 'react';

import PropTypes from 'prop-types';

import Animation from '@adsk/alloy-react-animation';
import Divider from '@adsk/alloy-react-divider';
import { useKeyEvent } from '@adsk/alloy-react-helpers';
import theme, {
  StylableRenderPropType,
  StyleProp,
  stylePropType,
} from '@adsk/alloy-react-theme';

import { WIDTHS } from './consts';
import { Container, Header, HeaderProps, Body } from './layout';

const DIVIDER_STYLE = {
  marginLeft: theme.spacing.M,
  width: 'calc(100% - 16px - 16px)',
};

const ESCAPE = 'Escape';

export type PanelProps = Omit<ComponentProps<typeof Animation>, 'title'> & {
  renderHeader?: (props: HeaderProps) => ReactElement;
  renderSubHeader?: (props: StylableRenderPropType) => ReactElement;
  renderFooter?: (
    props: StylableRenderPropType & { onClose?: () => void },
  ) => ReactElement;
  open?: boolean;
  width?: number | string;
  floating?: boolean;
  title?: ReactNode;
  onClose?: () => void;
  disableAnimation?: boolean;
  shouldCloseOnEsc?: boolean;
};

/**
 * A Panel component.  Provides access to secondary content which only appears when specifically requested by a user.
 */
const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (
    {
      children,
      className,
      title,
      onClose,
      onAnimationStart,
      onAnimationComplete,
      renderHeader = (p) => <Header {...p} />,
      renderSubHeader,
      renderFooter,
      open = false,
      style,
      width = WIDTHS.MEDIUM,
      floating = false,
      disableAnimation = false,
      shouldCloseOnEsc = true,
      ...props
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const [isFullyOpen, setIsFullyOpen] = useState<boolean | undefined>(open);
    const onEscKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === ESCAPE) {
          shouldCloseOnEsc && onClose?.();
        }
      },
      [shouldCloseOnEsc, onClose],
    );

    useKeyEvent(ESCAPE, { onKeyDown: onEscKeyDown });

    const handleOnAnimationComplete: typeof onAnimationComplete = (x) => {
      setIsFullyOpen(open);
      onAnimationComplete && onAnimationComplete(x);
    };

    const motionProps = { width: open ? width : 0 };
    const inlineStyle: StyleProp = {
      height: '100%',
      overflow: 'hidden',
      backgroundColor: theme.tokens.colors.background.primary.value,
      flexShrink: 0,
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      top: 0,
      width: disableAnimation ? 0 : undefined,
      ...(floating && {
        position: 'absolute',
        zIndex: theme.zIndex.panel,
        right: 0,
      }),
      ...(open && {
        boxShadow: floating
          ? theme.shadows.highElevation
          : `0 0 0 1px ${theme.tokens.colors.border.subtle.value}`,
        width: disableAnimation ? width : undefined,
      }),
    };

    const renderContents = () => (
      <div
        ref={innerRef}
        css={[
          {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            height: '100%',
          },
          {
            ...(isFullyOpen &&
              open && {
                maxWidth: '100%',
              }),
          },
        ]}
      >
        {renderHeader({ title, onClose, showEscapeLabel: shouldCloseOnEsc })}
        {renderSubHeader?.({
          style: {
            padding: `${theme.spacing.XXS + 2}px ${theme.spacing.M}px`,
          },
        })}
        {!!renderSubHeader && <Divider style={DIVIDER_STYLE} />}
        {children}
        {!!renderFooter && <Divider style={DIVIDER_STYLE} />}
        {renderFooter?.({
          style: {
            padding: `${theme.spacing.S}px ${theme.spacing.M}px ${theme.spacing.M}px`,
          },
          onClose,
        })}
      </div>
    );

    return disableAnimation ? (
      <div
        {...props}
        ref={ref}
        className={className}
        css={[inlineStyle, style]}
      >
        {renderContents()}
      </div>
    ) : (
      <Animation
        {...props}
        ref={ref}
        className={className}
        style={[inlineStyle, style]}
        initial={motionProps}
        animate={motionProps}
        transition={{
          ease: 'easeOut',
          duration: 0.3,
        }}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={handleOnAnimationComplete}
      >
        {renderContents()}
      </Animation>
    );
  },
);

Panel.displayName = 'Panel';

Panel.propTypes = {
  /** If panel is open or not */
  open: PropTypes.bool,
  /** Panel content */
  children: PropTypes.any,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** Render custom header content. Props: ({ title, onClose}) */
  renderHeader: PropTypes.func,
  /** Render custom sub header content */
  renderSubHeader: PropTypes.func,
  /** Render custom footer content */
  renderFooter: PropTypes.func,
  /** Width of Panel.  Use Panel.WIDTHS for standard widths */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** If panel should overlay content.  Default false for small/medium, true for large */
  floating: PropTypes.bool,
  /** Title to display in header */
  title: PropTypes.any,
  /** Function executed upon clicking close button */
  onClose: PropTypes.func,
  /** Function executed upon end of animation */
  onAnimationComplete: PropTypes.func,
  /** Disable panel animations */
  disableAnimation: PropTypes.bool,
  /** Set whether the panel should be closed when pressing the ESC key */
  shouldCloseOnEsc: PropTypes.bool,
};

export default Object.assign(Panel, {
  WIDTHS,
  Container,
  Header,
  Body,
});
