/**
 * Used for margin and padding.  Specs can be found in Figma:
 * https://www.figma.com/file/HZeXdPBnQzeUxjOZ95M4Px/%E2%9D%96-ACC-DS-%7C-Basics?node-id=520%3A0&t=RjZXWkH1oXlRoUqb-0
 */
export default {
  XXL: 48,
  XL: 32,
  L: 24,
  M: 16,
  S: 12,
  XS: 8,
  XXS: 4,
  XXXS: 2,
  //* @deprecated use XXL */
  xxLarge: 48,
  //* @deprecated use XL */
  xLarge: 32,
  //* @deprecated use L */
  large: 24,
  //* @deprecated use M */
  medium: 16,
  //* @deprecated use S */
  small: 12,
  //* @deprecated use XS */
  xSmall: 8,
  //* @deprecated use XXS */
  xxSmall: 4,
  //* @deprecated use XXXS */
  xxxSmall: 2,
} as const;
