import React, { ComponentProps, ReactElement, ReactNode, Ref } from 'react';

import PropTypes from 'prop-types';

import { stylePropType, StylableComponent } from '@adsk/alloy-react-theme';

export type TargetProps<T extends keyof React.JSX.IntrinsicElements> = Omit<
  StylableComponent<HTMLElement>,
  'as'
> & {
  shouldWrapChildren?: boolean;
  wrapper?: T;
};

const TargetWrapped = <T extends keyof React.JSX.IntrinsicElements>(
  {
    style,
    children,
    shouldWrapChildren = true,
    wrapper: Wrapper,
    ...props
  }: TargetProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const isNodeElement = (obj: ReactNode): obj is ReactElement =>
    !!obj && obj.hasOwnProperty('props');

  const shouldWrap = !isNodeElement(children) || shouldWrapChildren;

  if (!shouldWrap) {
    const childNode = React.Children.only(children);
    return isNodeElement(childNode)
      ? React.cloneElement(childNode, {
          ref,
          ...props,
        })
      : null;
  }

  return React.createElement(
    Wrapper || 'div',
    { ref, style, ...props },
    children,
  );
};

const Target = React.forwardRef(TargetWrapped);

Target.displayName = 'Target';

Target.propTypes = {
  /** className */
  className: PropTypes.string,
  /** style */
  style: stylePropType,
  /** children */
  children: PropTypes.any,
  /** should wrap */
  shouldWrapChildren: PropTypes.bool,
  /** wrapper element, eg: 'Div' */
  wrapper: PropTypes.any,
};

export default Target as <T extends keyof React.JSX.IntrinsicElements>(
  p: TargetProps<T> & { ref?: Ref<HTMLElement> },
) => ReactElement<ComponentProps<T>, T>;
