const formatMs = <T>(ms: number | T) =>
  typeof ms === 'number' ? `${Math.round(ms)}ms` : ms;

const defaultDuration = 200;
const defaultEasing = 'cubic-bezier(0.2, 0, 0.2, 1)';

const createTransition = (
  props: string | string[] = ['all'],
  options: { duration?: number; easing?: string; delay?: number } = {},
) => {
  const {
    duration: durationOption = defaultDuration,
    easing: easingOption = defaultEasing,
    delay = 0,
  } = options;

  if (!Array.isArray(props) && typeof props !== 'string') {
    console.error('Transition props should be string or array.');
  }

  const finalProps = Array.isArray(props) ? props : [props];

  return finalProps
    .map((p) => {
      return `${p} ${formatMs(durationOption)} ${easingOption} ${formatMs(
        delay,
      )}`;
    })
    .join(',');
};

export default {
  create: createTransition,
};
