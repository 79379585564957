export default {
  pill: 9999,
  circle: '50%',
  xxLarge: 16,
  xLarge: 12,
  large: 8,
  medium: 4,
  small: 2,
  none: 0,
} as const;
