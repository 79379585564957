import {
  getOrCreateGlobalContext,
  CONTEXT_NAMES,
} from '@adsk/alloy-react-context';

import { LocaleContextType } from './types';

export default getOrCreateGlobalContext<LocaleContextType>(
  CONTEXT_NAMES.LocaleContext,
);
