import React, { Context } from 'react';

const globalAlloyContextsKeyName = '__AlloyContexts__';

declare global {
  interface Window {
    [globalAlloyContextsKeyName]?: {
      [key: string]: Context<unknown>;
    };
  }
}

/**
 * Retrieves a global context from `window.__AlloyContexts__` or creates one if it does not exist
 * @param name Context name (usually retrieved from CONTEXT_NAMES - globalContexts.ts:38)
 * @returns A new React.Context
 * @example
 * import { getOrCreateGlobalContext } from '@adsk/alloy-react-helpers';
 *
 * const ComponentContext = getOrCreateGlobalContext<ComponentContext>(CONTEXT_NAME);
 * @see DatePicker Notification
 */
export const getOrCreateGlobalContext = <T>(name: string) => {
  const contexts = window[globalAlloyContextsKeyName] || {};
  if (!window[globalAlloyContextsKeyName]) {
    window[globalAlloyContextsKeyName] = contexts;
  }
  if (!contexts[name]) {
    contexts[name] = React.createContext<unknown>(undefined);
  }
  return contexts[name] as React.Context<T>;
};

export const getGlobalContext = (name: string) => {
  return window[globalAlloyContextsKeyName]?.[name];
};

export const CONTEXT_NAMES = {
  LocaleContext: 'LocaleContext',
  NotificationsContext: 'NotificationsContext',
} as const;
