// update ../../main/scss/vars.scss when changing this file
const fontFamily = {
  fontFamily: 'Artifakt Element, sans-serif',
};

export default {
  hero: {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '64px',
  },
  title: {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '42px',
  },
  heading1: {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '30px',
  },
  heading2: {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '26px',
  },
  heading3: {
    ...fontFamily,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
  },
  heading4: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  heading5: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  bodyLarge: {
    ...fontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
  },
  bodyLargeBold: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
  },
  bodyMedium: {
    ...fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodyMediumBold: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodySmall: {
    ...fontFamily,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  bodySmallBold: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  labelMedium: {
    ...fontFamily,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  labelSmall: {
    ...fontFamily,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption: {
    ...fontFamily,
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '15px',
  },
  captionBold: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '15px',
  },
  eyebrow: {
    ...fontFamily,
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '15px',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
} as const;
