import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px = 'M14 8.75H2a.75.75 0 0 1 0-1.5h12a.75.75 0 0 1 0 1.5Z';
const draw24px = 'M19,12.75H5a.75.75,0,0,1,0-1.5H19a.75.75,0,0,1,0,1.5Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const MinusIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'minus'),
) as IconWithRender;

MinusIcon.displayName = 'MinusIcon';
MinusIcon.propTypes = Icon.propTypes;
MinusIcon.renderIcon = (props) => <MinusIcon {...props} />;
export default MinusIcon;
