export default (message: string, useTrace = false) => {
  try {
    if (process.env.NODE_ENV === 'production') return;
  } catch (ex) {
    // process.env.NODE_ENV should be set via WebpackDefinePlugin or as a global in NodeJS.
    // If it is not, the above block will throw. This just discards the exception and
    // leaves it set to the default value, "production".
  }

  if (console) {
    const logFunction =
      useTrace && console.trace ? console.trace : console.warn;
    logFunction?.call(console, message);
  }
};
