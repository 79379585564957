import {
  useMemo,
  useState,
  MutableRefObject,
  RefObject,
  useCallback,
  RefCallback,
  useLayoutEffect,
} from 'react';

import { FlipOptions, HideOptions, ShiftOptions } from '@floating-ui/react-dom';

import { useDeprecate, useOnClickOutside } from '@adsk/alloy-react-helpers';

import usePopper from './usePopper';
import useVisibility, { UseVisibilityProps } from './useVisibility';
import { PLACEMENTS } from '../consts';
import type { Placement, RefProps } from '../types';

export type UseOverlayProps<TTarget extends HTMLElement> =
  UseVisibilityProps & {
    /** overlay position according to Overlay placements. */
    placement?: Placement;
    /** target element ref (get/set).  Prefer to use the ref returned in targetProps.  */
    target?: RefObject<TTarget>;
    /** overlay ref to overlay dom element.  Prefer to use the ref returned in overlayProps. */
    overlayRef?: MutableRefObject<HTMLElement | null>;
    /** Specify whether the overlay should trigger `onHide` when the user clicks outside the overlay. */
    rootClose?: boolean;
    offset?: number[];
    /** @deprecated No longer needed with the latest version of floating-ui.  Dependency to pass to usePopper's useLayoutEffect hook */
    layoutDependency?: unknown;
    /** Options that modify how overlays can shift */
    shiftArgs?: ShiftOptions;
    /** Options that modify how overlays can flip */
    flipArgs?: FlipOptions;
    /** Options that modify how overlays can hide (when target is hidden) */
    hideArgs?: HideOptions;
    /** Whether the overlay is conditionally rendered when closed.  If the overlay is rendered but hidden with CSS set this to false.  Defaults to true  */
    isOverlayConditionallyRendered?: boolean;
  };

function useOverlay<TTarget extends HTMLElement>({
  show,
  delayShow,
  delayHide,
  onHide,
  onShow,
  placement: placementProp,
  target: targetProp,
  overlayRef,
  rootClose = true,
  offset,
  layoutDependency,
  shiftArgs,
  flipArgs,
  hideArgs,
  isOverlayConditionallyRendered = true,
}: UseOverlayProps<TTarget>) {
  useDeprecate(
    !!layoutDependency,
    'useOverlay',
    'layoutDependency',
    '"No longer required"',
  );

  const toggle = useVisibility({
    show,
    onHide,
    onShow,
    delayShow,
    delayHide,
  });

  const [targetElement, setTargetElement] = useState<Element | null>(
    targetProp?.current || null,
  );
  const [overlayElement, setOverlayElement] = useState<HTMLElement | null>(
    overlayRef?.current || null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    /** Ideally users should use the target ref returned from `useOverlay`,
     * but in the case that they provide a `target`, we need to keep our state up to date with its current value
     */
    if (targetProp && targetProp?.current !== targetElement) {
      setTargetElement(targetProp.current);
    }
  }, [targetElement, targetProp]);

  const {
    placement: popperPlacement,
    popoverStyles,
    arrowStyles,
    refs,
  } = usePopper({
    placement: placementProp,
    targetElement,
    overlayElement,
    arrowElement,
    offset,
    shiftArgs,
    flipArgs,
    hideArgs,
    show: toggle.show,
    isOverlayConditionallyRendered,
  });

  const finalTargetRef: RefCallback<TTarget> = useCallback(
    (instance) => {
      if (!instance) {
        return;
      }

      setTargetElement(instance);

      if (targetProp) {
        (targetProp as MutableRefObject<TTarget>).current = instance;
      }

      refs.setReference(instance);
    },
    [targetProp, refs],
  );

  const finalOverlayRef: RefCallback<HTMLElement> = useCallback(
    (instance) => {
      if (!instance) {
        return;
      }

      setOverlayElement(instance);

      refs.setFloating(instance);

      if (overlayRef) {
        overlayRef.current = instance;
      }
    },
    [refs, overlayRef],
  );

  const notUndefined = <T>(x: undefined | T): x is T => x !== undefined;

  const refsToIgnore = useMemo(
    () => [targetProp, refs.floating, overlayRef].filter(notUndefined),
    [targetProp, refs.floating, overlayRef],
  );

  // Handle close on click outside
  const enableCloseOnClickOutside = rootClose && toggle.show;

  useOnClickOutside(enableCloseOnClickOutside, toggle.handleHide, refsToIgnore);

  const targetProps: RefProps<TTarget> = useMemo(
    () => ({
      ref: finalTargetRef,
    }),
    [finalTargetRef],
  );

  const overlayProps = useMemo(
    () => ({
      ref: finalOverlayRef,
      style: popoverStyles,
    }),
    [finalOverlayRef, popoverStyles],
  );

  const arrowProps = useMemo(
    () => ({
      ref: setArrowElement,
      style: arrowStyles,
    }),
    [arrowStyles],
  );

  return {
    ...toggle,
    placement: popperPlacement,
    targetProps,
    overlayProps,
    arrowProps,
  };
}

useOverlay.PLACEMENTS = PLACEMENTS;

export default useOverlay;
