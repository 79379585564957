import { useCallback, useEffect, useState } from 'react';

type KeyEventOptions = {
  onKeyDown?: (e: KeyboardEvent) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
};

/**
 * Observe keyboard interactions for a specific key.
 * @param {string} targetKey The keyboard key to observe, this comes from the KeyboardEvent.key value
 * @param {Object} options additional configuration object
 * @param {requestCallback} options.onDown fired when the key is pressed down
 * @param {requestCallback} options.onUp fired when the key is released up
 * @returns isPressed a boolean for currently pressed state
 * @example
 * import { useKeyEvent } from '@adsk/alloy-react-helpers';
 *
 * const { isPressed } = useKeyEvent('Escape', {
 *   onKeyDown: console.log,
 *   onKeyUp: console.log
 * });
 */
export default function useKeyEvent(
  targetKey: KeyboardEvent['key'],
  { onKeyDown, onKeyUp }: KeyEventOptions = {},
) {
  const [isPressed, setIsPressed] = useState<boolean>(false);
  const downHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        setIsPressed(true);
        onKeyDown?.(e);
      }
    },
    [targetKey, onKeyDown],
  );

  const upHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        setIsPressed(false);
        onKeyUp?.(e);
      }
    },
    [targetKey, onKeyUp],
  );

  useEffect(() => {
    document.addEventListener('keydown', downHandler, false);
    document.addEventListener('keyup', upHandler, false);
    return () => {
      document.removeEventListener('keydown', downHandler, false);
      document.removeEventListener('keyup', upHandler, false);
    };
  }, [downHandler, upHandler]);
  return { isPressed };
}
