import { useState } from 'react';

import noop from '../noop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChangeHandler<T> = (v: T, ...args: any[]) => void;

/**
 * Expose an interface to implement a controlled component
 * @param defaultValue Default value for the input using the hook
 * @param value Current value
 * @param onChange Callback to be fired on input change
 * @returns a tuple containing the final value and a callback for change event which runs `onChange`
 * @example
 * const ControlledInput = React.forwardRef(
 *   (
 *     {
 *       prop,
 *       defaultProp = false,
 *       onChange,
 *       ...props
 *     },
 *     ref
 *   ) => {
 *     const [value, handleOnChange] = useControlled(
 *       defaultProp,
 *       prop,
 *       onChange
 *     );
 *
 *     return (
 *       <input
 *         ref={ref}
 *         name={name}
 *         value={value}
 *         onChange={onChange}
 *       />
 *     );
 *   }
 * )
 * @see Button Checkbox CommentField Menu RadioButton Slider Table Tag Tile Toggle
 */
export default function useControlled<T>(
  defaultValue: T,
  value?: T,
  onChange: ChangeHandler<T> = noop,
): [T, ChangeHandler<T>] {
  const [innerValue, setInnerValue] = useState<T>(defaultValue);

  let finalValue = innerValue;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let finalOnChange = function (v: T, ...args: any[]) {
    onChange(v, ...args);
    setInnerValue(v);
  };

  // controlled
  if (value !== undefined) {
    // this makes it strict, if value is undefined and onChange is set, it will not use the onChange.
    finalOnChange = onChange;
    finalValue = value;
  }

  return [finalValue, finalOnChange];
}
