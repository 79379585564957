import {
  CSSProperties,
  DetailedHTMLProps,
  DOMAttributes,
  ElementType,
  HTMLAttributes,
} from 'react';

import PropTypes, { Validator } from 'prop-types';
import { CSSObject, StyledObject, StyleFunction } from 'styled-components';
import {
  Keyframes,
  RuleSet,
  StyledComponentBrand,
} from 'styled-components/dist/types';

/**
 * This and the CSSProp type is copied from styled-components V6.  This allows us to support both V5 and V6.
 *
 * This is because `null` was built into this type in V6 but we had added it to our style prop in V5 here.
 * So when we built our library with V6 TS would simplify the type and remove the `null` from the style prop because it
 * was part of this type in V6.  However when used with V5 it would not have `null` in the type and would error.
 *
 * This could be removed and use the type from styled-components directly when we remove support for V5 in a breaking change.
 */
type Interpolation<Props extends object> =
  | StyleFunction<Props>
  | StyledObject<Props>
  | TemplateStringsArray
  | string
  | number
  | false
  | undefined
  | null
  | Keyframes
  | StyledComponentBrand
  | RuleSet<Props>
  | Interpolation<Props>[];

/**
 * The `css` prop is not declared by default in the types as it would cause `css` to be present
 * on the types of anything that uses styled-components indirectly, even if they do not use the
 * babel plugin.
 *
 * To enable support for the `css` prop in TypeScript, create a `styled-components.d.ts` file in
 * your project source with the following contents:
 *
 * ```ts
 * import type { CSSProp } from "styled-components";
 *
 * declare module "react" {
 *  interface Attributes {
 *    css?: CSSProp;
 *  }
 * }
 * ```
 *
 * In order to get accurate typings for `props.theme` in `css` interpolations, see
 * {@link DefaultTheme}.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StyleProp = Interpolation<any>;

export const asPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.elementType,
]) as Validator<ElementType>;

export const stylePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
  PropTypes.array,
]) as Validator<StyleProp>;

export type StylableComponent<
  Tag,
  Attributes extends DOMAttributes<Tag> = HTMLAttributes<Tag>,
> = Omit<Attributes, 'style' | 'css'> & {
  as?: ElementType;
  style?: null | StyleProp;
};

export type DetailedStylableComponent<
  Attributes extends HTMLAttributes<Tag>,
  Tag,
  DetailedAttributes = DetailedHTMLProps<Attributes, Tag>,
> = Omit<DetailedAttributes, 'style' | 'css'> & {
  as?: ElementType;
  style?: null | StyleProp;
};

/**
 * Type used for render props
 *
 * ```typescript
 * type MyComponentProps = StylableComponent<HTMLDivElement> & {
 * renderBody?: (props: StylableRenderPropType) => ReactElement;
 * }
 * ```
 */
export type StylableRenderPropType = { style: CSSProperties };

const isNotEmpty = (value: string) => !!value && value.trim().length > 0;

const parseStyleString = (styleString: string) =>
  styleString
    .split(';')
    .filter(isNotEmpty)
    .map((cur) => cur.split(':'))
    .reduce(
      (acc, val) => {
        const [key, value] = val;
        const camelCaseKey = key
          .trim()
          .replace(/-./g, (css) => css.toUpperCase()[1]);
        acc[camelCaseKey] = value.trim();
        return acc;
      },
      {} as Record<string, string>,
    );

/**
 * Converts styled-components type of style (array or object) to one compatible with react.
 * This is useful for converting our style before providing it to users through render functions.
 * If a user of ours does not use styled-components it can be difficult for them to apply it
 * without us converting it first.
 *
 * @param style styled-components specific style
 * @returns Regular react-style style object
 */
export const flattenStyle = (
  style?: StyleProp | null,
): CSSObject | undefined => {
  if (!style) {
    return undefined;
  }

  if (typeof style === 'string') {
    return parseStyleString(style);
  }

  if (!Array.isArray(style)) {
    return style as CSSObject;
  }

  return style
    .map((x) => flattenStyle(x))
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
};
