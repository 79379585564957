import { Requireable, Validator } from 'prop-types';

/**
 * Wrapper for PropTypes to remove the allowance of null. This fixes the error: `Type 'boolean | null | undefined' is not assignable to type 'boolean | undefined'. Type 'null' is not assignable to type 'boolean | undefined'.`
 * @param propType The PropType you wish to use
 * @returns A function with the signuature of a Validator, but does not allow null
 * @example
 * import { optionalPropType } from '@adsk/alloy-react-helpers';
 *
 * type MyComponentProps = {
 *   isDisabled?: boolean;
 * }
 *
 * MyComponent.propTypes = {
 *   isDisabled: optionalPropType(PropTypes.bool)
 * }
 * @see DatePicker Menu PermissionSelect Schedule
 */
export const optionalPropType =
  <T>(propType: Requireable<T>): Validator<T | undefined> =>
  (props: { [key: string]: unknown }, propName: string, ...rest) => {
    return props[propName] === undefined
      ? null
      : propType(props, propName, ...rest);
  };
