import { useMemo, useRef } from 'react';

import { MDText } from 'i18n-react';

import useOnlyLocale from './useOnlyLocale';
import { defaultLocale } from '../consts';
import { createTranslationLoader } from '../TLoader';
import { TranslationFileLoader, UseLocaleFns } from '../types';

/**
 * @name useMDText - this function loads translatiosn and creates the MDText instance.
 * @param {function} translationFileLoader - function to load translation file.
 * @param {string} locale [defaultLocale]
 */
const useMDText = (
  translationFileLoader: TranslationFileLoader = () => {},
  locale: string,
): MDText => {
  const translationFileLoaderRef = useRef(translationFileLoader);
  const T = useMemo(() => {
    const translations = createTranslationLoader({
      translationFileLoader: translationFileLoaderRef.current,
    })(locale);
    return new MDText(translations || {});
  }, [locale]);

  return T;
};

/**
 * This function allows you to load a locale using the locale from the provider.
 * @name useLocale
 * @param {function} translationFileLoader - function to load translation file.
 * @see ActivityLog Banner CloseButton ColorPicker CommentCard CommentField DatePicker Dropdown Dropzone FilterButton LoadMoreData Modal Schedule Table datetime
 */
export default (
  translationFileLoader?: TranslationFileLoader,
): UseLocaleFns => {
  const { locale, setLocale } = useOnlyLocale();

  const defaultedLocale = locale ?? defaultLocale;

  const T = useMDText(translationFileLoader, defaultedLocale);

  return { T, locale: defaultedLocale, setLocale };
};
