import { MenuCategoryType, MenuItemType } from './types';

export function getSelectedItems<TKey extends string | number>(
  selectedItems: TKey[],
  eventKey: TKey,
  category: MenuCategoryType<TKey>,
) {
  const multiSelection = category.multiSelection || false;
  if (multiSelection) {
    return [...selectedItems, eventKey];
  } else {
    const categoryKeys = category.itemOptions.map((option) => option.key);
    const newItems = selectedItems.filter((key) => !categoryKeys.includes(key));
    return [...newItems, eventKey];
  }
}

function getAllItemOptions<TKey extends string | number>(
  nested?: (MenuItemType<TKey> | MenuCategoryType<TKey>)[],
) {
  if (nested === undefined) {
    return undefined;
  }
  const itemOptions = nested.flatMap(
    (nest) => (nest as MenuCategoryType<TKey>).itemOptions,
  );
  let allOptions = itemOptions;
  itemOptions.forEach((option) => {
    if (option.hasOwnProperty('nested')) {
      const nestedK = getAllItemOptions(option.nested) || [];
      allOptions = [...allOptions, ...nestedK];
    }
  });

  return allOptions;
}

export function getAllNestedKeys<TKey extends string | number>(
  nested?: (MenuItemType<TKey> | MenuCategoryType<TKey>)[],
) {
  if (!nested) {
    return undefined;
  }
  const itemOptions = getAllItemOptions(nested);
  return itemOptions?.flatMap((option) => option.key);
}

export function getOptionsDuplicatedKeys<TKey extends string | number>(
  options: MenuCategoryType<TKey>[],
) {
  const itemOptions = options
    .flatMap((opt) => opt.itemOptions)
    .filter((opt) => opt);
  const keys = itemOptions.map((opt) => opt.key);
  const nestedKeys =
    getAllNestedKeys(
      itemOptions
        .filter((opt) => !!opt.nested)
        .flatMap((opt) => opt.nested || []),
    ) || [];
  const sorted_arr = [...keys, ...nestedKeys].sort();
  const results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}
