import { useEffect } from 'react';

import deprecate from '../deprecate';

/**
 * Throws a warning in console to alert that a prop is deprecated and a different prop should be used for a given component
 * @param enabled Fires the warning
 * @param component Component name
 * @param prop Prop to be marked as deprecated
 * @param nextProp Prop that should replace the deprecated prop
 */
function useDeprecate(
  enabled: boolean,
  component: string,
  prop: string,
  nextProp: string,
) {
  useEffect(() => {
    if (enabled) {
      deprecate(component, prop, nextProp);
    }
    //for it to be called only on mount.
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useDeprecate;
