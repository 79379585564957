import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M5.36 13.85a.74.74 0 0 1-.54-.23.75.75 0 0 1 0-1.06L9.57 8 4.83 3.44a.75.75 0 0 1 0-1.06.75.75 0 0 1 1.06 0l5.29 5.1a.75.75 0 0 1 0 1.08l-5.29 5.1a.77.77 0 0 1-.53.19Z';
const draw24px =
  'M9.78,18a.75.75,0,0,1-.52-1.29L14.12,12,9.26,7.31a.75.75,0,0,1,1-1.08l5.42,5.23a.75.75,0,0,1,0,1.08L10.3,17.77A.77.77,0,0,1,9.78,18Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const ChevronRightIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'chevronRight'),
) as IconWithRender;

ChevronRightIcon.displayName = 'ChevronRightIcon';
ChevronRightIcon.propTypes = Icon.propTypes;
ChevronRightIcon.renderIcon = (props) => <ChevronRightIcon {...props} />;
export default ChevronRightIcon;
