import React, { ReactNode } from 'react';

import PropTypes from 'prop-types';

import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

type MenuCategoryProps = Omit<StylableComponent<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  titleUnderline?: boolean;
};

/** A MenuCategory component to allow multiple lists in one menu */
const MenuCategory = React.forwardRef<HTMLDivElement, MenuCategoryProps>(
  ({ children, title, titleUnderline, style = {}, className }, ref) => (
    <div ref={ref} className={className} css={[style]}>
      {title && (
        <h4
          css={[
            theme.typography.heading4,
            {
              color: theme.tokens.colors.text.secondary.value,
              margin: `${theme.spacing.M}px ${theme.spacing.M}px 0px`,
            },
            titleUnderline && {
              borderBottom: `1px solid ${theme.tokens.colors.border.subtle.value}`,
              paddingBottom: 5,
            },
          ]}
        >
          {title}
        </h4>
      )}
      {children}
    </div>
  ),
);

MenuCategory.displayName = 'MenuCategory';

MenuCategory.propTypes = {
  /** The content to category */
  children: PropTypes.any,
  /* Styles applied to the root element */
  style: stylePropType,
  /* Class applied to the root element */
  className: PropTypes.string,
  /** Category title */
  title: PropTypes.any,
  /** show underline below title */
  titleUnderline: PropTypes.bool,
};

export default MenuCategory;
