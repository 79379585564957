import React, {
  ComponentProps,
  ComponentPropsWithRef,
  ReactElement,
} from 'react';

import PropTypes from 'prop-types';

import {
  useForwardedRef,
  Container as ContainerType,
} from '@adsk/alloy-react-helpers';
import Portal from '@adsk/alloy-react-portal';

import { PLACEMENTS, VARIANTS } from './consts';
import useOverlay, { UseOverlayProps } from './hooks/useOverlay';
import Arrow from './layout/Arrow';
import Blank from './layout/Blank';
import Body from './layout/Body';
import Container from './layout/Container';
import Target from './layout/Target';
import type { Placement, RefProps } from './types';

export type OverlayProps<TTarget extends HTMLElement> =
  UseOverlayProps<TTarget> & {
    container?: ContainerType;
    renderTarget?: (props: {
      show?: boolean;
      handleToggle: () => void;
      targetProps: RefProps<TTarget>;
    }) => ReactElement<RefProps<TTarget>>;
    renderOverlay?: (props: {
      placement: Placement;
      handleHide: () => void;
      targetProps: RefProps<TTarget>;
      overlayProps: ComponentPropsWithRef<typeof Container>;
      arrowProps: ComponentProps<typeof Arrow>;
    }) => ReactElement;
  };

/**
 * React Overlay wrapper component for useOverlay
 */
const Overlay = <TTarget extends HTMLElement>({
  placement: placementProp = PLACEMENTS.BOTTOM,
  show: showProp,
  container = document.body,
  target: targetRef,
  renderTarget,
  renderOverlay,
  ...props
}: OverlayProps<TTarget>) => {
  const target = useForwardedRef(targetRef || null);

  const {
    show,
    placement,
    targetProps,
    overlayProps,
    arrowProps,
    onHide,
    handleToggle,
  } = useOverlay<TTarget>({
    placement: placementProp,
    show: showProp,
    target,
    ...props,
  });

  return (
    <>
      {renderTarget?.({
        show,
        handleToggle,
        targetProps,
      })}

      {renderOverlay && show ? (
        <Portal container={container}>
          {renderOverlay({
            placement,
            handleHide: onHide,
            targetProps,
            overlayProps,
            arrowProps,
          })}
        </Portal>
      ) : null}
    </>
  );
};

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  /**
   * whether the popover is showed or not. should be sent only for
   * controlled elements
   */
  show: PropTypes.bool,
  /**
   * callback to be called on show
   */
  onShow: PropTypes.func,
  /**
   * callback to be called on hide
   */
  onHide: PropTypes.func,
  /**
   * delay before show in milliseconds
   */
  delayShow: PropTypes.number,
  /**
   * delay before hide in milliseconds
   */
  delayHide: PropTypes.number,
  /** overlay position according to Overlay placements. */
  placement: PropTypes.oneOf(Object.values(useOverlay.PLACEMENTS)),
  /** target element ref (get/set).  */
  target: PropTypes.object,
  /** overlay ref to overlay dom element. */
  overlayRef: PropTypes.any,
  /** Specify whether the overlay should trigger `onHide` when the user clicks outside the overlay. */
  rootClose: PropTypes.bool,
  /** Dependency to pass to usePopper's useLayoutEffect hook */
  layoutDependency: PropTypes.any,
  /** Container is Overlay Portal location */
  container: PropTypes.object,
  /**  render prop for Target:
   *    <Target
          show={show}
          handleToggle={handleToggle}
          targetProps={targetProps}
        />
   */
  renderTarget: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** render prop for Overlay:
   * <Overlay
      placement={placement}
      handleHide={handleHide}
      targetProps={targetProps}
      overlayProps={overlayProps}
      arrowProps={arrowProps}
    />
   */
  renderOverlay: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default Object.assign(Overlay, {
  VARIANTS,
  PLACEMENTS,
  Target,
  Container,
  Body,
  Blank,
  Arrow,
});
