import { RefObject, useEffect } from 'react';

/**
 * This hook handles clicks outside.
 *
 * @param enabled - whether this hook is enabled or not.
 * @param handler - handler to call when clicked outside.
 * @param refs - either a ref or an array of refs. Clicking those refs would not trigger the handler.
 *               IMPORTANT! - Use memoization when passing an array.
 * @see Menu Overlay Tooltip
 */
export default function useOnClickOutside(
  enabled: boolean,
  handler: (event: MouseEvent) => void,
  refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
) {
  useEffect(() => {
    if (!enabled) return;
    const listener = (event: MouseEvent) => {
      const refsArr = Array.isArray(refs) ? refs : [refs];
      // Do nothing if clicking tooltipRef’s element or descendent elements
      if (
        refsArr.some((ref) => ref?.current?.contains(event.target as Element))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [enabled, handler, refs]);
}
