/*
 *
 *
 * DONT FORGET:
 * update ../../main/scss/vars.scss when changing this file!
 *
 *
 */
export default {
  black: '#000000' /* black */,
  white: '#ffffff' /* white */,

  adskBlue100: '#CDEAF7' /* adsk-blue-100 */,
  adskBlue300: '#9bd5ef' /* adsk-blue-300 */,
  adskBlue500: '#0696d7' /* adsk-blue-500 */,
  adskBlue600: '#0686C2' /* adsk-blue-600 */,
  adskBlue700: '#006eaf' /* adsk-blue-700 */,

  charcoal050: '#f6f6f6' /* charcoal-050 */,
  charcoal100: '#eeeeee' /* charcoal-100 */,
  charcoal200: '#dcdcdc' /* charcoal-200 */,
  charcoal300: '#cccccc' /* charcoal-300 */,
  charcoal400: '#bbbbbb' /* charcoal-400 */,
  charcoal500: '#999999' /* charcoal-500 */,
  charcoal600: '#808080' /* charcoal-600 */,
  charcoal700: '#666666' /* charcoal-700 */,
  charcoal800: '#4d4d4d' /* charcoal-800 */,
  charcoal900: '#3c3c3c' /* charcoal-900 */,

  red100: '#faeded' /* red-100 */,
  red300: '#f1a7a7' /* red-300 */,
  red500: '#ec4a41' /* red-500 */,
  red600: '#DD2222' /* red-600 */,
  red700: '#A61A1A' /* red-700 */,

  pink300: '#FF9DCE' /* pink-300 */,
  pink500: '#E84396' /* pink-500 */,
  pink700: '#C72877' /* pink-700 */,

  purple300: '#C9A7F9' /* purple-300 */,
  purple500: '#A76EF5' /* purple-500 */,
  purple700: '#8558C5' /* purple-700 */,

  yellowOrange100: '#FEECD1' /* yellow-orange-100 */,
  yellowOrange300: '#FDDAA4' /* yellow-orange-300 */,
  yellowOrange500: '#FAA21B' /* yellow-orange-500 */,
  yellowOrange700: '#916010' /* yellow-orange-700 */,

  green100: '#E7F2D9' /* green-100 */,
  green300: '#B7D78C' /* green-300 */,
  green500: '#87B340' /* green-500 */,
  green700: '#5D822C' /* green-700 */,

  darkBlue100: '#E1EBF7' /* dark-blue-100 */,
  darkBlue300: '#A3BCDC' /* dark-blue-300 */,
  darkBlue500: '#4679B9' /* dark-blue-500 */,
  darkBlue700: '#12437F' /* dark-blue-700 */,
  darkBlue900Alpha45: 'rgba(12, 44, 84, 0.45)' /* Overlay color */,

  turquoise100: '#D6F2EF' /* turquoise-100 */,
  turquoise300: '#84D7CE' /* turquoise-300 */,
  turquoise500: '#32BCAD' /* turquoise-500 */,
  turquoise700: '#268D82' /* turquoise-700 */,

  salmon300: '#FFB3A6' /* salmon-300 */,
  salmon500: '#F26A52' /* salmon-500 */,
  salmon700: '#A84A39' /* salmon-700 */,

  brown300: '#BB7A14' /* brown-300 */,
  brown500: '#7D510E' /* brown-500 */,
  brown700: '#523609' /* brown-700 */,

  hoverHalo: 'rgba(128, 128, 128, 0.15)',
  activeHalo: 'rgba(128, 128, 128, 0.25)',
  focusHalo: 'rgba(6, 150, 215, 0.35)',

  acsLogoBlue: '#1858A8',
  adskBlue500Alpha15: 'rgba(6, 150, 215, 0.15)' /* adsk-blue-500 alpha 0.15 */,
  adskBlue300Alpha35: 'rgb(155, 213, 239, 0.35)' /* adsk-blue-300 alpha 0.35 */,
  green300Alpha35: 'rgb(183, 215, 140, 0.35)' /* green-300 alpha 0.35 */,
  yellowOrange300Alpha35:
    'rgb(253, 218, 164, 0.35)' /* yellow-orange-300 alpha 0.35 */,
  red300Alpha35: 'rgb(241, 167, 167, 0.35)' /* red-300 alpha 0.35 */,

  _greyAlpha: 'rgba(60, 60, 60, 0.1)' /* internal usage only */,
  _adskBlue100Alpha: 'rgb(205, 234, 247, 0.35)' /* internal usage only */,
  _blueShadow: 'rgba(12, 44, 84, 0.25)',
} as const;
