import { useMemo } from 'react';

type IconVariation = { d: string; viewBox: string };
export type IconVariations = { _24: IconVariation; _16?: IconVariation };

const useIconVariation = ({
  size = 24,
  icons,
}: {
  size?: number;
  icons: IconVariations;
}): IconVariation => {
  return useMemo(() => {
    if (icons._16 && size <= 16) {
      return icons._16;
    }
    return icons._24;
  }, [size, icons]);
};

export default useIconVariation;
