export default <T>(
  obj: T,
  keysToPick: (keyof T)[] = [],
): Pick<T, (typeof keysToPick)[number]> => {
  return obj
    ? (Object.keys(obj)
        .filter((key) => keysToPick.includes(key as keyof T))
        .reduce<Partial<T>>((result, key) => {
          result[key as keyof T] = obj[key as keyof T];
          return result;
        }, {}) as Pick<T, keyof T>)
    : obj;
};
