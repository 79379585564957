import { ReactNode } from 'react';

import PropTypes from 'prop-types';

import { renderPortal, Container } from '@adsk/alloy-react-helpers';

type PortalProps = {
  children: ReactNode;
  container?: Container;
};

/**
 * A Portal component.  Provides a wrapper of ReactDOM.createPortal
 */
const Portal = ({ children, container }: PortalProps) =>
  renderPortal(children, container);

Portal.displayName = 'Portal';

Portal.propTypes = {
  /** portal container, when container empty children rendered within current node */
  container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /** portal content */
  children: PropTypes.any,
};

export default Portal;
