import { useRef } from 'react';

let uniqueId = 0;
const getUniqueId = () => (uniqueId++).toString();

export const resetInstanceId = () => {
  uniqueId = 0;
};

const useInstanceId = () => {
  const idRef = useRef<string | null>(null);
  if (idRef.current === null) {
    idRef.current = getUniqueId();
  }
  return idRef.current;
};

export default useInstanceId;
