import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M8 14a1.2 1.2 0 0 1 0-2.4A1.2 1.2 0 0 1 8 14Zm1.2-6a1.2 1.2 0 1 0-2.4 0 1.2 1.2 0 1 0 2.4 0Zm0-4.8a1.2 1.2 0 1 0-2.4 0 1.2 1.2 0 1 0 2.4 0Z';
const draw24px =
  'M13.5,6A1.5,1.5,0,1,1,12,4.5,1.5,1.5,0,0,1,13.5,6ZM12,10.5A1.5,1.5,0,1,0,13.5,12,1.5,1.5,0,0,0,12,10.5Zm0,6A1.5,1.5,0,1,0,13.5,18,1.5,1.5,0,0,0,12,16.5Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const MoreVerticalIcon = forwardRef<SVGSVGElement, ComponentProps<typeof Icon>>(
  generateRenderedIcon(icons, 'moreVertical'),
) as IconWithRender;

MoreVerticalIcon.displayName = 'MoreVerticalIcon';
MoreVerticalIcon.propTypes = Icon.propTypes;
MoreVerticalIcon.renderIcon = (props) => <MoreVerticalIcon {...props} />;
export default MoreVerticalIcon;
