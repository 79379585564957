import React from 'react';

import PropTypes from 'prop-types';

import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

import { VARIANTS } from '../consts';
import { getContainerBodyStyle } from '../helpers';
import { ThemeVariant } from '../types';

type BodyProps = StylableComponent<HTMLDivElement> & {
  variant?: ThemeVariant;
  padding?: string | number;
  maxWidth?: string | number;
};

const Body = React.forwardRef<HTMLDivElement, BodyProps>(
  (
    {
      className,
      style,
      children,
      variant = VARIANTS.DARK,
      padding = `${theme.spacing.XS}px ${theme.spacing.S}px`,
      maxWidth = '300px',
      ...props
    },
    ref,
  ) => (
    <div
      className={className}
      ref={ref}
      css={[
        theme.typography.bodySmall,
        { padding, maxWidth, overflowWrap: 'break-word' },
        getContainerBodyStyle(variant),
        style,
      ]}
      {...props}
    >
      {children}
    </div>
  ),
);

Body.displayName = 'Body';

Body.propTypes = {
  /** className */
  className: PropTypes.string,
  /** style */
  style: stylePropType,
  /** children */
  children: PropTypes.any,
  /** variants */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** padding */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** maxWidth */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Object.assign(Body, { VARIANTS });
