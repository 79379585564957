import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Standarize React `ref` for use on Typescript
 * @param forwardedRef can be a `ref`, a function to be performed on `ref.current`, or `null`
 * @returns a React `ref`
 * @example
 * import { useForwardedRef } from '@adsk/alloy-react-helpers';
 *
 * const elementRef = useForwardedRef(ref || null);
 * @see AnimateHeight CommentField Dropdown InlineEdit Modal Overlay PermissionSelect Schedule SearchField Slider Textarea Tooltip WorkflowBar
 */
export default function useForwardedRef<T>(
  forwardedRef:
    | ((instance: T | null) => void)
    | React.MutableRefObject<T | null>
    | null,
) {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!forwardedRef) return;

    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      forwardedRef.current = innerRef.current;
    }
  }, [innerRef, forwardedRef]);

  return innerRef as MutableRefObject<T>;
}
