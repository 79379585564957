import colors from './colors';
import halos from './halos';
import transitions from './transitions';

export const getInteractiveStyles = (disabled: boolean) => {
  if (disabled) {
    return {
      cursor: 'not-allowed',
      boxShadow: 'none',
    };
  }

  return {
    transition: transitions.create(['box-shadow', 'border-color']),
    '&:hover': {
      boxShadow: halos.hover,
    },
    '&:focus-within': {
      boxShadow: halos.focus,
    },
    '&:active': {
      boxShadow: halos.active,
      borderColor: colors.charcoal500,
    },
  };
};
