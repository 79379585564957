import { defaultLocale } from './consts';
import type { TranslationFileLoader } from './types';

export const createTranslationLoader =
  ({
    translationFileLoader,
  }: {
    translationFileLoader: TranslationFileLoader;
  }) =>
  (locale: string) => {
    try {
      return translationFileLoader({ locale });
    } catch (e) {
      // This is duplicated from alloy-react-helpers' warn(), but adding the dependency would harm our dependency tree
      if (process && process.env && process.env.NODE_ENV !== 'production') {
        console &&
          console.warn &&
          console.warn(
            `Locale has changed to '${locale}' but the component doesn't have the correct localization file for it.`,
          );
      }
      return translationFileLoader({ locale: defaultLocale });
    }
  };
