import React from 'react';

import PropTypes from 'prop-types';

import theme, {
  stylePropType,
  StylableComponent,
} from '@adsk/alloy-react-theme';

type PanelBodyProps = StylableComponent<HTMLDivElement>;

const PanelBody = React.forwardRef<HTMLDivElement, PanelBodyProps>(
  ({ children, className, style, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={className}
        css={[
          {
            overflow: 'auto',
            flexGrow: 1,
            padding: theme.spacing.M,
          },
          style,
        ]}
        {...props}
      >
        {children}
      </div>
    );
  },
);

PanelBody.displayName = 'PanelBody';

PanelBody.propTypes = {
  /** Styles applied to the root element */
  style: stylePropType,
  /** Body content */
  children: PropTypes.any,
  /** Class applied to the root element */
  className: PropTypes.string,
};

export default PanelBody;
